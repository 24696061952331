<template>
  <div>
		<v-container>
			<navbar title="قوانین و مقررات" route='/panel'/>
			<v-main>
				<v-card class="pa-3 pt-15 flat-card card" color="surface">
          <div style="font-size: 14px;">
            <p class="font" v-html="about.full_text_origin"></p>
          </div>
        </v-card>
			</v-main>
		</v-container>
	</div>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import { mapState } from 'vuex'
import { get_content } from '@/models/content'
export default {
	components: {
		navbar,
	},
	computed: {
		...mapState({
		about: state =>state.content
		})
	},
	created () {
		const config = JSON.parse(localStorage.getItem('config')).other_configs
		get_content(JSON.parse(config).law_content_id)
		
	}
}
</script>